import React from "react";
import Hacker from "../images/diabla_hacker.png";

const Section1 = () => {
  return (
    <div id="section1" className="max-w-[1200px] mx-auto">
      <h1 className="font-days text-center p-4 font-light text-4xl">
        What will we do for you?{" "}
        <span className="font-bold text-3xl underline decoration-3">
          EVERYTHING
        </span>
      </h1>

      <section className="md:flex items-center justify-center m-6">
        <img
          src={Hacker}
          className="hidden md:block h-[50%] w-[50%] max-w-[600px] mt-[23rem]  lg:mt-[6rem] z-[-1] "
          alt=""
        />
        <article className="p-8 max-w-[500px] m-6 rounded-[28px] bg-[#850000] mx-auto">
          <div>
            <h2 className="font-bold text-white text-3xl">1. AI Bot Systems</h2>
            <p className="text-white mt-3">
              We are proud to offer advanced AI technology to help you optimize
              and grow your OnlyFans account. Our AI systems include features
              such as content optimization, personalized recommendations for
              your subscribers, and automated messaging.
            </p>
          </div>
          <div className="mt-8">
            <h2 className="font-bold text-white text-3xl">2. Analytics</h2>
            <p className="text-white mt-3">
              We understand the importance of understanding your audience and
              measuring the success of your OnlyFans account.
              <strong>
                That's why we offer a variety of analytics tools to help you
                track your performance and make data-driven decisions.
              </strong>
            </p>
          </div>
          <div className="mt-8">
            <h2 className="font-bold text-white text-3xl">
              3. Chatting services
            </h2>
            <p className="text-white mt-3">
              Our team will manage all your <strong>chat services</strong>{" "}
              engaging with every one of your subscribers to
              <strong>maximize all your incomes</strong> (This will give you{" "}
              <strong>TONS of free time</strong>).
            </p>
          </div>
          <div className="mt-8">
            <h3 className="font-bold text-white text-1xl">
              We will literally make money while you sleep 24/7!
            </h3>
          </div>
          <img src={Hacker} alt="" className="mt-5 md:hidden" />
        </article>
      </section>
    </div>
  );
};

export default Section1;
