import React from "react";
import Money from "../images/diabla-money.png";
import Money1 from "../images/diabla-money1.png";
import { Button1, Button2 } from "./Button";

export default function Hero() {
  return (
    <div className="max-w-[1200px] mx-auto pt-20">
      <h1 className="font-lili text-white text-[25px] mt-1 text-center p-4 md:text-4xl">
        With discretion, trust, loyalty and empowerment... Welcome to the place
        where your dreams come true.
      </h1>
      <div className="md:flex justify-center items-start mx-auto">
        <div className="md:flex justify-center items-start m-6  ">
          <div className="rounded-[40px] bg-black text-white text-center my-4 mx-auto p-4 md: max-w-[400px]">
            <p className="font-cabin p-1.5 lg:text-xl">COMMISSION BASED 💰</p>
            <p className="font-cabin p-1.5 lg:text-xl">✅ 0 RISK FOR YOU</p>
            <p className="font-cabin p-1.5 lg:text-xl">
              ✅ NO MONEY IN ADVANCE
            </p>
            <p className="font-cabin p-1.5 lg:text-xl">
              📸 PROVIDE THE CONTENT
            </p>
            <p className="font-cabin p-1.5 lg:text-xl">WE DO THE TRICK ✨</p>
            <p className="font-cabin p-1.5 lg:text-xl">
              TEAM WITH ADVANCED AI SOFTWARE 🤖
            </p>
            <p className="font-cabin p-2 lg:text-xl">
              YEARS OF EXPERIENCE IN THE PORN INDUSTRY AND MARKETING ✌️
            </p>
          </div>

          <div className="flex items-start justify-center md:flex-col-reverse">
            <div className="ml-10 md:hidden">
              <a href="#form">
                <Button1 className="z-[-91]" />
              </a>
            </div>
            <img
              src={Money}
              alt="/"
              className="z-[-1] overflow-hidden md:hidden ml-[2rem] w-[24.5rem]"
            />
            <img
              src={Money1}
              alt="/"
              className="hidden z-[-1] overflow-hidden md:block ml-[2rem] w-[24.5rem]"
            />
          </div>
        </div>
      </div>
      <div className="hidden md:flex justify-center mt-[-11rem] ml-[-33.5rem]">
        <a href="#form">
          <Button2 className="z-[-91]" />
        </a>
      </div>
    </div>
  );
}
