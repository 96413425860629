import React, { useState } from "react";
import { AiOutlineMenu, AiOutlineClose } from "react-icons/ai";
import Logo from "../images/logo.jpg";

export default function Navbar() {
  const [nav, setNav] = useState(true);

  const handleNav = () => {
    setNav(!nav);
  };

  return (
    <div className="fixed w-[100%] flex justify-around items-center h-20 mx-auto px-4 bg-black text-white">
      <a href="/" className="font-bold text-3xl text-[#DF0000] ">
        <img src={Logo} width={250} alt="" />
      </a>
      <ul className="hidden md:flex items-center">
        <li className="pl-4">
          <a href="/">Home</a>
        </li>
        <li className="pl-4">
          <a href="#Socials">Socials</a>
        </li>
        <li className="pl-4">
          <a href="#section1">About us</a>
        </li>
        <li className="pl-4">
          <button className="bg-white text-black p-1.5 px-3 rounded-lg font-bold">
            <a href="#form">Contact us</a>
          </button>
        </li>
      </ul>
      <div onClick={handleNav} className="block md:hidden">
        {!nav ? <AiOutlineClose size={22} /> : <AiOutlineMenu size={22} />}
      </div>
      <div
        className={
          !nav
            ? "fixed left-0 top-0 w-[60%] bg-black h-full border-r border-r-gray-900 ease-in-out duration-500"
            : "fixed left-[-100%]"
        }
      >
        <h1 className="font-bold text-3xl m-4 mt-6 text-[#DF0000]">
          <img src={Logo} width={250} alt="" />
        </h1>
        <ul className="uppercase pt-2">
          <li className="p-4 border-b border-gray-600 text-[#DF0000] font-bold">
            Home
          </li>
          <li className="p-4  border-b border-gray-600 text-[#DF0000] font-bold">
            Socials
          </li>
          <li className="p-4 border-b border-gray-600 text-[#DF0000] font-bold">
            About Us
          </li>
          <li className="p-4 text-[#DF0000] font-bold">Contact Us</li>
        </ul>
      </div>
    </div>
  );
}
