import React from "react";

export default function Form() {
  return (
    <div>
      <form
        id="form"
        action="https://formsubmit.co/diabla.agency@gmail.com"
        method="POST"
        className="text-center w-[300px] md:text-center border-white border-2 md:w-[500px] p-5 mx-auto rounded-lg my-5"
      >
        <div className="md:flex gap-5 justify-center font-lili text-white">
          <div className="nameDiv mt-2">
            {" "}
            <label for="nameInput">What's your name? *</label> <br />
            <input
              className="p-2 w-[200px] text-center outline-none bg-transparent border-b-2 border-white"
              type="text"
              placeholder="Type your name here"
              name="user_name"
              required
            />
          </div>

          <div className="ageDiv mt-2">
            <label for="nameInput">What's your age? *</label>
            <br />
            <input
              className="p-2 w-[200px] text-center outline-none bg-transparent border-b-2 border-white"
              name="user_age"
              type="number"
              placeholder="Type your age here"
              max="99"
              required
            />
          </div>
        </div>

        <div className="md:flex gap-5 justify-center p-1 font-lili text-white">
          <div className="genreDiv mt-4">
            <label for="genreInput">What's your genre?</label>
            <br />
            <input
              className="p-2 w-[200px] text-center outline-none bg-transparent border-b-2 border-white"
              name="user_genre"
              type="text"
              placeholder="Type your genre here"
            />
          </div>

          <div className="genreDiv mt-4">
            <label for="genreInput">What's your WhatsApp?</label>
            <br />
            <input
              className="p-2 w-[200px] text-center outline-none bg-transparent border-b-2 border-white"
              type="text"
              name="user_number"
              placeholder="Type your number here"
            />
          </div>
        </div>

        <div className="p-2 font-lili text-white">
          <label for="emailInput">Your email *</label> <br />
          <input
            className="md:w-[420px] p-2 text-center text-bold outline-none bg-transparent border-b-2 text-white"
            name="user_email"
            type="email"
            placeholder="Type your email here..."
            required
          />
        </div>

        <input
          className="bg-black w-[200px] text-white md:w-[420px] p-2 mt-2 rounded-lg font-lili"
          type="submit"
          name="submit"
          value="Submit"
        />
      </form>
    </div>
  );
}
