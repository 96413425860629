import React from "react";
import ReactDOM from "react-dom/client";
import Navbar from "./components/Navbar";
import Hero from "./components/Hero";
import Section1 from "./components/Section1";
import "./index.css";
import Section2 from "./components/Section2";
import Section3 from "./components/Section3";
import Socials from "./components/Socials";

const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
  <React.StrictMode>
    <Navbar />
    <Hero />
    <hr className="mt-[-1.5rem] max-w-[1440px] mx-auto z-2 md:mt-[2.5rem]" />
    <div className="max-w-[1440px] mx-auto h-[50px] bg-[#DF0000]"></div>
    <Section1 />
    <hr className="max-w-[1440px] mx-auto z-2 md:mt-[-2rem]" />
    <div className="max-w-[1440px] mx-auto h-[30px] bg-[#DF0000]"></div>
    <Section2 />
    <hr className="max-w-[1440px] mx-auto z-2 md:mt-[-2rem]" />
    <div className="max-w-[1440px] mx-auto h-[50px] bg-[#DF0000]"></div>
    <Section3 />
    <Socials />
  </React.StrictMode>
);
