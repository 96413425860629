import React from "react";
import Hacker from "../images/diabla-broker.png";

export default function Section2() {
  return (
    <div className="max-w-[1200px] mx-auto">
      <section className="m-6 md:flex items-center justify-center lg:ml-[5rem]">
        <article className="p-8 max-w-[500px] m-6 rounded-[28px] bg-[#850000] mx-auto">
          <div>
            <h2 className="font-bold text-white text-3xl">
              4. Account and content management
            </h2>
            <p className="text-white mt-3">
              We will manage all your other social media related to your OF such
              as TikTok, Twitter, IG , Reddit, Snapchat and{" "}
              <strong>
                we’ll work to redirect all your traffic to your OF site.
              </strong>
            </p>
          </div>
          <div className="mt-8">
            <h2 className="font-bold text-white text-3xl">
              5. Viral strategy and planning
            </h2>
            <p className="text-white mt-3">
              We know the trends and how to viralize your content and create a
              plan specifically for you on how to become a high revenue OnlyFans
              model. We ensure long-term growth of your account and constantly
              generate new fans while your current fans remain. We examine your
              accounts, see your strengths and understand why they are growing.
            </p>
          </div>
          <div className="mt-8">
            <h2 className="font-bold text-white text-3xl">
              6. A team backing you up
            </h2>
            <p className="text-white mt-3">
              Are you out of imagination, motivation or lacking the knowledge
              with banking systems, legal stuff related to OF?{" "}
              <strong>We are willing to help you with any inconvenience</strong>{" "}
              you can deal with in your way to become a high-revenue model.
              Because we understand a happy, motivated and driven person is the
              combination to make your dreams come true.{" "}
              <strong>We are here, for you.</strong>
            </p>
          </div>
          <img src={Hacker} alt="" className="mt-5 md:hidden" />
        </article>
        <img
          src={Hacker}
          className="hidden md:block h-[50%] w-[50%] max-w-[600px] mt-[22rem] lg:mt-[12rem] z-[-1] "
          alt=""
        />
      </section>
    </div>
  );
}
