import React from "react";
import { BsTelegram, BsInstagram } from "react-icons/bs";

export default function Socials() {
  return (
    <div id="Socials" className="flex justify-center items-center gap-10 p-4">
      <a target={"_blank"} rel={"noreferrer"} href="https://t.me/DiablaCEO">
        <div className="cursor-pointer flex items-center justify-center gap-3">
          <label
            htmlFor="BsTelegram"
            className="hidden md:flex text-bold text-2xl font-days text-white"
          >
            Telegram
          </label>
          <BsTelegram className="text-5xl text-white" />
        </div>
      </a>
      <a
        target={"_blank"}
        rel={"noreferrer"}
        href="https://www.instagram.com/diabla_agency/"
      >
        <div className="cursor-pointer flex items-center justify-center gap-3">
          <BsInstagram className="text-5xl text-white" />
          <label
            htmlFor="BsInstagram"
            className="hidden md:flex text-bold text-2xl font-days text-white"
          >
            Instagram
          </label>
        </div>
      </a>
    </div>
  );
}
