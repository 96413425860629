import React from "react";
import Angel from "../images/angelordemon.png";
import Form from "./Form";

export default function Section3() {
  return (
    <div>
      <h1 className="font-days text-[28px] text-center text-white">
        What do we expect from you?
      </h1>
      <p className="text-center p-2 text-white">
        Your only job is to send us your content,{" "}
        <strong>we will do the rest!</strong>
      </p>
      <p className="text-[28px] text-center">
        <span className="font-days text-[28px] font-bold text-white">
          ¿Angel
        </span>{" "}
        or
        <span className="font-days text-[28px] font-bold text-[#710000]">
          {" "}
          Devil?
        </span>
      </p>
      <img src={Angel} className="mx-auto lg:w-[500px] h-[450px]" alt="/" />
      <Form />
    </div>
  );
}
